import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../views/Login'),
        meta: { title: "中青年出血性脑卒中康复运动平台" },
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('../views/Home'),
        meta: { title: "中青年出血性脑卒中康复运动平台" },
        children: [{
            path: '/nurse/list',
            name: 'nurse_list',
            component: () =>
                import ('../views/nurse/list')
        }, {
            path: '/nurse/detail',
            name: 'nurse_detail',
            component: () =>
                import ('../views/nurse/detail')
        }, {
            path: '/admin/list',
            name: 'admin_list',
            component: () =>
                import ('../views/admin/list')
        }, {
            path: '/admin/detail',
            name: 'admin_detail',
            component: () =>
                import ('../views/admin/detail')
        }, {
            path: '/helpCenter/reportList',
            name: 'helpCenterReportList',
            component: () =>
                import ('../views/helpCenter/reportList')
        }, {
            path: '/helpCenter/reportDetail',
            name: 'helpCenterReportDetail',
            component: () =>
                import ('../views/helpCenter/reportDetail')
        }, {
            path: '/helpCenter/videoList',
            name: 'helpCenterVideoList',
            component: () =>
                import ('../views/helpCenter/videoList')
        }, {
            path: '/helpCenter/videoDetail',
            name: 'helpCenterVideoDetail',
            component: () =>
                import ('../views/helpCenter/videoDetail')
        }, {
            path: '/happyPulse/videoList',
            name: 'happyPulseVideoList',
            component: () =>
                import ('../views/happyPulse/videoList')
        }, {
            path: '/happyPulse/videoDetail',
            name: 'happyPulseVideoDetail',
            component: () =>
                import ('../views/happyPulse/videoDetail')
        }, {
            path: '/psychologicalClassroom/reportList',
            name: 'psychologicalClassroomReportList',
            component: () =>
                import ('../views/psychologicalClassroom/reportList')
        }, {
            path: '/psychologicalClassroom/reportDetail',
            name: 'psychologicalClassroomReportDetail',
            component: () =>
                import ('../views/psychologicalClassroom/reportDetail')
        }, {
            path: '/psychologicalClassroom/tagList',
            name: 'psychologicalClassroomTagList',
            component: () =>
                import ('../views/psychologicalClassroom/tagList')
        }, {
            path: '/treeHole/q&AList',
            name: 'treeHoleQ&AList',
            component: () =>
                import ('../views/treeHole/q&AList')
        }, {
            path: '/treeHole/q&ADetail',
            name: 'treeHoleQ&ADetail',
            component: () =>
                import ('../views/treeHole/q&ADetail')
        }, {
            path: '/drugAdministration/drugClassList',
            name: 'drugAdministrationDrugClassList',
            component: () =>
                import ('../views/drugAdministration/drugClassList')
        }, {
            path: '/drugAdministration/drugClassDetail',
            name: 'drugAdministrationDrugClassDetail',
            component: () =>
                import ('../views/drugAdministration/drugClassDetail')
        }, {
            path: '/user/userList',
            name: 'user_userList',
            component: () =>
                import ('../views/user/userList')
        }, {
            path: '/user/userDetail',
            name: 'user_userDetail',
            component: () =>
                import ('../views/user/userDetail')
        }, {
            path: '/dashboard/index',
            name: 'dashboard',
            component: () =>
                import ('../views/dashboard/index')
        }]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router