<script>
// 部署
const host='https://recover-platform.tbs-datum.com'; //最新域名
const sysName='中青年出血性脑卒中康复运动平台';
  export default
  {
    host, //host
    sysName,
  }
</script>

