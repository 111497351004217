import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    userInfo: {},
    allMenu: {},
    search:''
  },
  mutations: {
    // 更新用户登录信息
    userInfo (state, userInfo) {
      state.userInfo = userInfo;
    },
    // 全部菜单
    allMenu (state, allMenu) {
      state.allMenu = allMenu;
    },
    // list 搜索条件
    search (state,search){
      state.search = search;
    }
    
  },
  actions: {
  },
  modules: {
  }
})
